import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { DataService } from './data.service';

export interface BackgroundModel {
  id: number;
  src?: string;
  color: string;
}

export interface ThemeModel {
  id: number;
  backgroundId: number;
  color1: string;
  color2: string;
  color3: string;
  color4: string;
  color5: string;
}

export interface FullThemeModel {
  background: BackgroundModel;
  theme: ThemeModel;
  blur: number;
}

@Injectable({
  providedIn: 'root'
})
export class ThemeService {
  state = new BehaviorSubject(null);
  backgroundType = 'clean';
  tempBlur = null;

  backgrounds = [
    {id: 1, color: '#989898'},
    {id: 2, color: '#CF103E'},
    {id: 3, color: '#F3950B'},
    {id: 4, color: '#DDCD0B'},
    {id: 5, color: '#3DAA3D'},
  ];

  themes = [
    {id: 1, backgroundId: 1, name: 'Corporate', color1: '#88BCBA', color2: '#234D54', color3: '#112D31', color4: '#4E4B41', color5: '#6E685A'},
    {id: 2, backgroundId: 1, name: 'Dynamic', color1: '#2E4455', color2: '#660523', color3: '#DA7C93', color4: '#367071', color5: '#193333'},

    {id: 3, backgroundId: 2, name: 'Morning', color1: '#75C9F7', color2: '#F99DB3', color3: '#75C9F7', color4: '#F8DE9F', color5: '#FEFFB2'},
    {id: 4, backgroundId: 2, name: 'Auburn', color1: '#F9A5BA', color2: '#F8914F', color3: '#EC5531', color4: '#D34438', color5: '#92253C'},

    {id: 5, backgroundId: 3, name: 'Cheerful', color1: '#DD6600', color2: '#F4C500', color3: '#A8E49C', color4: '#C4F4A6', color5: '#F5FED3'},
    {id: 6, backgroundId: 3, name: 'Creative', color1: '#338380', color2: '#F0DFB4', color3: '#F6EB75', color4: '#97D8F8', color5: '#72BBE6'},
				
    {id: 7, backgroundId: 4, name: 'Invigorate', color1: '#03E7F2', color2: '#6CBED6', color3: '#578798', color4: '#6CDDDD', color5: '#338380'},
    {id: 8, backgroundId: 4, name: 'Energetic', color1: '#B381C6', color2: '#CBCBCB', color3: '#76BE98', color4: '#338380', color5: '#8B7E91'},

    {id: 9, backgroundId: 5, name: 'Clean', color1: '#8CF299', color2: '#267B77', color3: '#2BAFA9', color4: '#DDEEEE', color5: '#EDF297'},
    {id: 10, backgroundId: 5, name: 'Vibrant', color1: '#E84747', color2: '#6DC4CD', color3: '#E5C71C', color4: '#98C9EE', color5: '#CDE8F1'},
  ];

  constructor(private dataService: DataService) { }

  getBackgroundType() {
    return this.backgroundType;
  }

  setBackgroundType(bgType) {
    this.backgroundType = bgType;
    this.state.next((new Date).getTime());
    return true;
  }

  getBackgrounds() {
    return this.backgrounds;
  }

  getThemes(backgroundId): ThemeModel[] {
    return this.themes.filter(theme => theme.backgroundId == backgroundId) as ThemeModel[];
  }

  getTheme(): FullThemeModel {
    const theme = this.dataService.getTheme();
    return {
      background: this.backgrounds.find(b => b.id == theme.background) || this.backgrounds[0],
      theme: this.themes.find(t => t.id == theme.theme) || this.themes[0],
      blur: theme.blur != null ? theme.blur : 0
    } as FullThemeModel;
  }

  getThemeStyle() {
    const theme = this.getTheme();
    const styles = [];

    styles.push('--themeColor1: ' + theme.theme.color1);
    styles.push('--themeColor2: ' + theme.theme.color2);
    styles.push('--themeColor3: ' + theme.theme.color3);
    styles.push('--themeColor4: ' + theme.theme.color4);
    styles.push('--themeColor5: ' + theme.theme.color5);

    return styles.join('; ');
  }

  setBackground(id) {
    const background = this.backgrounds.find(b => b.id == id);
    if (!background) {
      return false;
    }

    const theme = this.dataService.getTheme();
    theme.background = id;
    theme.theme = null;

    this.dataService.setTheme(theme);
    this.tempBlur = theme.blur != null ? theme.blur : 8;
    this.state.next((new Date).getTime());
  }

  setBlur(amount) {
    if (amount < 0 || amount > 15) {
      return false;
    }

    const theme = this.dataService.getTheme();
    theme.blur = amount;
    
    this.dataService.setTheme(theme);
    this.tempBlur = null;
    this.state.next((new Date).getTime());
  }

  getTempBlur() {
    return this.tempBlur;
  }

  setTempBlur(amount) {
    if (amount < 0 || amount > 15) {
      return false;
    }

    this.tempBlur = amount;
    this.state.next((new Date).getTime());
  }

  setTheme(id) {
    const theme = this.dataService.getTheme();

    const t = this.themes.find(t => t.id == id);
    if (!t || t.backgroundId != theme.background) {
      return false;
    }

    theme.theme = id;
    
    this.dataService.setTheme(theme);
    this.tempBlur = null;
    this.state.next((new Date).getTime());
  }
}
