import { FirebaseX } from '@ionic-native/firebase-x/ngx';
import { ApiService } from './api.service';
import { BehaviorSubject } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "@ionic-native/firebase-x/ngx/index";
import * as i2 from "./api.service";
var FirebaseService = /** @class */ (function () {
    function FirebaseService(firebaseX, apiService) {
        this.firebaseX = firebaseX;
        this.apiService = apiService;
        this.hasPermission = new BehaviorSubject(null);
        this.newMessage = new BehaviorSubject(null);
    }
    FirebaseService.prototype.init = function () {
        var _this = this;
        this.firebaseX.hasPermission()
            .then(function (hasPermission) {
            _this.hasPermission.next(hasPermission);
            if (hasPermission) {
                _this.firebaseX.onTokenRefresh()
                    .subscribe(function (token) {
                    _this.apiService.addDevice(token)
                        .subscribe();
                });
                _this.firebaseX.onMessageReceived()
                    .subscribe(function (data) {
                    _this.newMessage.next((new Date).getTime());
                    console.log("FCM message: " + data);
                });
            }
        });
    };
    FirebaseService.prototype.requestNotifications = function () {
        var _this = this;
        this.firebaseX.grantPermission()
            .then(function () {
            _this.init();
        });
    };
    FirebaseService.ngInjectableDef = i0.defineInjectable({ factory: function FirebaseService_Factory() { return new FirebaseService(i0.inject(i1.FirebaseX), i0.inject(i2.ApiService)); }, token: FirebaseService, providedIn: "root" });
    return FirebaseService;
}());
export { FirebaseService };
