import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { DataService } from './data.service';
import { API_HOST, API_CLIENT_ID, API_CLIENT_SECRET } from '../../environments/environment';
import { take } from 'rxjs/operators';
import * as Sentry from 'sentry-cordova';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  url = API_HOST;

  constructor(private http: HttpClient, private dataService: DataService) { }

  serialize(obj) {
    var str = [];
    for (var p in obj)
      if (obj.hasOwnProperty(p)) {
        str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
      }
    return str.join("&");
  }

  delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  send(url, data = null, attempt = 0) {
    let request = this.http.get<any>(`${this.url}${url}`, {
          headers: {
            'X-Requested-With': 'XMLHttpRequest',
            'Accept': 'application/json',
            'Accept-Language': this.dataService.getLanguage('en'),
          },
        });
        
    if (data) {
      request = this.http.post<any>(`${this.url}${url}`, data, {
            headers: {
              'X-Requested-With': 'XMLHttpRequest',
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'Accept-Language': this.dataService.getLanguage('en'),
            },
          });
    }

    return new Observable(observer => {
        request.pipe(take(1))
          .subscribe(data => {
            observer.next(data);
            observer.complete();
          }, async error => {
            console.log(error.status);
            if (error.status || attempt > 2) {
              observer.error(error);
              return;
            }

            await this.delay(1000);
            this.send(url, data, attempt + 1)
              .subscribe((data) => {
                observer.next(data);
                observer.complete();
              }, error => observer.error(error));
          });
      })
      .pipe(take(1));
  }

  languages(): Observable<any> {
    return this.send('/languages');
  }

  badges(): Observable<any> {
    return this.send('/badges');
  }

  badge(id): Observable<any> {
    return this.send('/badge/'+id);
  }

  me(): Observable<any> {
    return this.send('/me');
  }

  invite(name: string, email: string): Observable<any> {
    return this.send('/invite', {
      name,
      email,
    });
  }

  checkLogin(email: string): Observable<any> {
    return this.send('/login/check', {
      email,
    });
  }

  update(name: string, email: string, password: string = null, newPassword: string = null): Observable<any> {
    return this.send('/me', {
      name,
      email, 
      password,
      newPassword,
    });
  }

  login(email: string, password: string): Observable<any> {
    return this.send('/oauth/token', {
      grant_type: 'password',
      client_id: API_CLIENT_ID,
      client_secret: API_CLIENT_SECRET,
      username: email,
      password,
      scope: ''
    });
  }

  resetpass(token: string, password: string = null): Observable<any> {
    return this.send('/resetpass/' + token, password != null ? {password} : null);
  }

  confirm(token: string): Observable<any> {
    return this.send('/confirm/' + token);
  }

  forgotpass(email: string): Observable<any> {
    return this.send('/forgotpass', {email});
  }

  logout(): Observable<any> {
    return this.send('/logout', {sent: new Date()});
  }

  checkEmail(email: string): Observable<any> {
    return this.send('/login', {
      email
    });
  }

  register(name: string, email: string, password: string, companyCode = null, language = 'en'): Observable<any> {
    return this.send('/register', {
      name,
      email, 
      password,
      companyCode,
      language
    });
  }

  language(language: string): Observable<any> {
    return this.send('/language', {language});
  }

  setupComplete(): Observable<any> {
    return this.send('/setup/complete', {sent: new Date()});
  }

  addDevice(token: string): Observable<any> {
    return this.send('/me/device', {token});
  }

  courses(): Observable<any> {
    return this.send('/courses');
  }

  courseCategory(id: string, categoryId: string, data = null): Observable<any> {
    return this.send('/course/' + id + '/categories/' + categoryId, data);
  }

  goals(id: string = null): Observable<any> {
    return this.send('/goals', id ? {id} : null);
  }

  nudges(id: string = null): Observable<any> {
    return this.send('/nudges', id ? {id} : null);
  }

  nudge(id: string = null, read: boolean = false): Observable<any> {
    return this.send('/nudge/' + id, read ? {read} : null);
  }

  rate(rating: number = null, shared: boolean = false): Observable<any> {
    return this.send('/rate', {
      rating,
      shared
    });
  }
}
