import { BehaviorSubject, Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { DataService } from './data.service';
import { ApiService } from './api.service';
import { ErrorService } from './error.service';
import * as i0 from "@angular/core";
import * as i1 from "./api.service";
import * as i2 from "./data.service";
import * as i3 from "./error.service";
var AuthenticationService = /** @class */ (function () {
    function AuthenticationService(apiService, dataService, errorService) {
        this.apiService = apiService;
        this.dataService = dataService;
        this.errorService = errorService;
        this.user = null;
        this.company = null;
        this.state = new BehaviorSubject(null);
        this.last_authorisation = null;
    }
    AuthenticationService.prototype.init = function () {
        var authToken = this.dataService.getAuthToken();
        if (authToken) {
            this.check().subscribe();
        }
        else {
            this.state.next(false);
        }
    };
    AuthenticationService.prototype.update = function (name, email, password, newPassword) {
        var _this = this;
        if (password === void 0) { password = null; }
        if (newPassword === void 0) { newPassword = null; }
        this.dataService.setAuthEmail(email);
        var update = new Observable(function (observer) {
            _this.apiService.update(name, email, password, newPassword)
                .subscribe(function (data) {
                _this.check()
                    .subscribe(function (data) {
                    observer.next(data);
                    observer.complete();
                }, function (error) { return observer.error(error); });
            }, function (error) { return observer.error(error); });
        })
            .pipe(take(1));
        return update;
    };
    AuthenticationService.prototype.check = function (attempt) {
        var _this = this;
        if (attempt === void 0) { attempt = 0; }
        var check = new Observable(function (observer) {
            _this.apiService.me()
                .subscribe(function (data) {
                _this.user = data.user;
                _this.company = data.company;
                _this.dataService.setLanguage(data.user.language);
                _this.dataService.setAuthEmail(data.user.email);
                _this.state.next((new Date).getTime());
                _this.last_authorisation = (new Date()).getTime();
                observer.next(_this.getAuth());
                observer.complete();
            }, function (error) {
                if (!error.status) {
                    if (!_this.last_authorisation || _this.last_authorisation <= (new Date()).getTime() - (60 * 60 * 24 * 14 * 1000)) {
                        if (attempt > 2) {
                            _this.dataService.setAuthToken(null);
                            _this.user = null;
                            _this.company = null;
                            _this.state.next(false);
                            observer.error(error);
                            return;
                        }
                        _this.errorService.show('error.cannot-reach', 'error.retry-connection', function () {
                            _this.check(attempt + 1)
                                .subscribe(function (data) {
                                observer.next(data);
                                observer.complete();
                            }, function (err) {
                                observer.error(err);
                            });
                        });
                    }
                    else {
                        observer.next(_this.getAuth());
                        observer.complete();
                    }
                }
                if (error.status == 401 || attempt > 2) {
                    _this.dataService.setAuthToken(null);
                    _this.user = null;
                    _this.company = null;
                    _this.state.next(false);
                    observer.error(error);
                    return;
                }
                _this.errorService.show('error.cannot-reach', 'error.retry-connection', function () {
                    _this.check(attempt + 1)
                        .subscribe(function (data) {
                        observer.next(data);
                        observer.complete();
                    }, function (err) {
                        observer.error(err);
                    });
                });
            });
        })
            .pipe(take(1));
        return check;
    };
    AuthenticationService.prototype.confirm = function (token) {
        var _this = this;
        var confirm = new Observable(function (observer) {
            _this.apiService.confirm(token)
                .subscribe(function (data) {
                observer.next(data);
                observer.complete();
            }, function (error) { return observer.error(error); });
        })
            .pipe(take(1));
        return confirm;
    };
    AuthenticationService.prototype.login = function (email, password) {
        var _this = this;
        if (password === void 0) { password = null; }
        this.dataService.setAuthEmail(email);
        var login = new Observable(function (observer) {
            _this.apiService.login(email, password)
                .subscribe(function (data) {
                if (!password) {
                    observer.next(data);
                    observer.complete();
                    return;
                }
                _this.dataService.setAuthToken('Bearer ' + data.access_token);
                _this.check()
                    .subscribe(function (data) {
                    observer.next(data);
                    observer.complete();
                }, function (error) { return observer.error(error); });
            }, function (error) { return observer.error(error); });
        })
            .pipe(take(1));
        return login;
    };
    AuthenticationService.prototype.forgotPassword = function (email) {
        var _this = this;
        this.dataService.setAuthEmail(email);
        var forgotpass = new Observable(function (observer) {
            _this.apiService.forgotpass(email)
                .subscribe(function (data) {
                observer.next(data);
                observer.complete();
            }, function (error) { return observer.error(error); });
        })
            .pipe(take(1));
        return forgotpass;
    };
    AuthenticationService.prototype.register = function (name, email, password, companyCode) {
        var _this = this;
        if (companyCode === void 0) { companyCode = null; }
        this.dataService.setAuthEmail(email);
        var register = new Observable(function (observer) {
            _this.apiService.register(name, email, password, companyCode, _this.dataService.getLanguage())
                .subscribe(function (data) {
                if (!data.access_token) {
                    observer.next(data);
                    observer.complete();
                    return;
                }
                _this.dataService.setAuthToken('Bearer ' + data.access_token);
                _this.check()
                    .subscribe(function (data) {
                    observer.next(data);
                    observer.complete();
                }, function (error) { return observer.error(error); });
            }, function (error) { return observer.error(error); });
        })
            .pipe(take(1));
        return register;
    };
    AuthenticationService.prototype.logout = function () {
        var _this = this;
        var logout = new Observable(function (observer) {
            _this.apiService.logout()
                .subscribe(function (data) {
                _this.dataService.setAuthToken(null);
                _this.user = null;
                _this.company = null;
                _this.state.next(false);
                observer.next(data);
                observer.complete();
            }, function (error) { return observer.error(error); });
        })
            .pipe(take(1));
        return logout;
    };
    AuthenticationService.prototype.isAuthenticated = function () {
        return this.state.value ? true : false;
    };
    AuthenticationService.prototype.getAuth = function () {
        return {
            user: this.user,
            company: this.company,
        };
    };
    AuthenticationService.prototype.getToken = function () {
        return this.dataService.getAuthToken();
    };
    AuthenticationService.prototype.getEmail = function () {
        return this.dataService.getAuthEmail();
    };
    AuthenticationService.prototype.getName = function () {
        return this.dataService.getAuthName();
    };
    AuthenticationService.ngInjectableDef = i0.defineInjectable({ factory: function AuthenticationService_Factory() { return new AuthenticationService(i0.inject(i1.ApiService), i0.inject(i2.DataService), i0.inject(i3.ErrorService)); }, token: AuthenticationService, providedIn: "root" });
    return AuthenticationService;
}());
export { AuthenticationService };
