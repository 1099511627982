import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import * as Sentry from 'sentry-cordova';
import { SocialServiceConfig } from "ngx-social-button";
export function getAuthServiceConfigs() {
    return new SocialServiceConfig()
        .addFacebook('498142814130863');
}
import { CDN_HOST, SENTRY_DSN } from '../environments/environment';
export function createTranslateLoader(http) {
    return new TranslateHttpLoader(http, CDN_HOST + '/assets/i18n/', '.json?' + (new Date).getTime());
}
import { iosTransitionAnimation } from '@ionic/core/dist/collection/utils/transition/ios.transition';
export function transitionAnimation(foo, el, opts) {
    return Promise.resolve(iosTransitionAnimation(el, opts));
}
Sentry.init({
    dsn: SENTRY_DSN,
    beforeSend: function (event, hint) {
        var error = hint.originalException;
        if (error && error.status && error.status == 401) {
            return null;
        }
        return event;
    },
});
var ɵ0 = (createTranslateLoader);
var AppModule = /** @class */ (function () {
    function AppModule() {
    }
    return AppModule;
}());
export { AppModule };
export { ɵ0 };
