import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { Storage } from '@ionic/storage';
import { BehaviorSubject } from 'rxjs';

const TOKEN_KEY = 'AUTH_TOKEN';
const EMAIL_KEY = 'AUTH_EMAIL';
const NAME_KEY = 'AUTH_NAME';
const LANG_KEY = 'SELECTED_LANGUAGE';
const THEME_KEY = 'SELECTED_THEME';
const INTRO_KEY = 'SHOW_INTRO';
const NOTIFICATION_REQUEST_KEY = 'SHOW_NOTIFICATION_REQUEST';
const PROFILE_INFO_SHOWN_KEY = 'SHOW_PROFILE_INFO';

export interface ThemeDataModel {
  background: number;
  theme: number;
  blur: number;
}

@Injectable({
  providedIn: 'root'
})
export class DataService {
  ready = new BehaviorSubject(false);

  authToken: string = null;
  authName: string = null;
  authEmail: string = null;
  language: string = null;
  notificationRequested: number = null;
  profileInfoShown: number = null;
  intro: number = null;
  theme: ThemeDataModel = {
    background: null,
    theme: null,
    blur: null,
  };

  constructor(private storage: Storage, private platform: Platform) {
    this.platform.ready().then(async () => {
      this.getStorage();
    });
  }

  async getStorage() {
    let res;

    res = await this.storage.get(TOKEN_KEY);
    if (res) {
      this.authToken = res;
    }

    res = await this.storage.get(EMAIL_KEY);
    if (res) {
      this.authEmail = res;
    }

    res = await this.storage.get(NAME_KEY);
    if (res) {
      this.authName = res;
    }

    res = await this.storage.get(LANG_KEY);
    if (res) {
      if (res == ' English ') {
        res = 'en';
        this.storage.set(LANG_KEY, res);
      }
      this.language = res;
    }

    res = await this.storage.get(INTRO_KEY);
    if (res) {
      this.intro = res;
    }

    res = await this.storage.get(THEME_KEY);
    if (res) {
      this.theme = res;
    }

    res = await this.storage.get(NOTIFICATION_REQUEST_KEY);
    if (res) {
      this.notificationRequested = res;
    }

    res = await this.storage.get(PROFILE_INFO_SHOWN_KEY);
    if (res) {
      this.profileInfoShown = res;
    }

    this.ready.next(true);
  }

  // Auth Token
  getAuthToken() {
    return this.authToken;
  }

  setAuthToken(token: string) {
    this.storage.set(TOKEN_KEY, token);
    this.authToken = token;
  }

  // Auth Name
  getAuthName() {
    return this.authName;
  }

  setAuthName(name: string) {
    this.storage.set(NAME_KEY, name);
    this.authEmail = name;
  }

  // Auth Email
  getAuthEmail() {
    return this.authEmail;
  }

  setAuthEmail(email: string) {
    this.storage.set(EMAIL_KEY, email);
    this.authEmail = email;
  }

  // Language
  getLanguage(def: string = null) {
    return this.language || def;
  }

  setLanguage(language: string) {
    this.storage.set(LANG_KEY, language);
    this.language = language;
  }

  // Notification Request
  getNotificationRequested() {
    return this.notificationRequested;
  }

  setNotificationRequested(requested: number) {
    this.storage.set(NOTIFICATION_REQUEST_KEY, requested);
    this.notificationRequested = requested;
  }

  // Profile Info Shown
  getProfileInfoShown() {
    return this.profileInfoShown;
  }

  setProfileInfoShown(shown: number) {
    this.storage.set(PROFILE_INFO_SHOWN_KEY, shown);
    this.profileInfoShown = shown;
  }

  // Theme
  getTheme() {
    return this.theme;
  }

  setTheme(theme: ThemeDataModel) {
    this.storage.set(THEME_KEY, theme);
    this.theme = theme;
  }

  // Intro
  getIntro() {
    return this.intro;
  }

  setIntro(intro: number) {
    this.storage.set(INTRO_KEY, intro);
    this.intro = intro;
  }

}
