import {Injectable} from '@angular/core';
import {LoadingController} from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ErrorService {
    state = new BehaviorSubject(false);
    message = null;
    button = null;
    callback = null;

    constructor(private loadingController: LoadingController, private translate: TranslateService) {
    }

    getMessage() {
      return this.message;
    }

    getButton() {
      return this.button;
    }

    click() {
      this.hide();
      if (! this.callback) {
        return;
      }
      this.callback();
    }

    async show(message: string, button: string = null, callback: any = null) {
        if (this.state.value) {
            this.hide();
        }

        this.message = message;
        this.button = button;
        this.callback = callback;

        this.state.next(true);
    }

    async hide() {
        this.state.next(false);
    }

}