<ion-app>
  <app-background></app-background>
  <div class="require-rotate" [ngClass]="{'video': themeType == 'video-lock'}"></div>
  <div [class]="'theme-' + themeType">
    <div class="loading" *ngIf="loading">
      <img class="logo" src="/assets/logos/full.png" alt="Drive iQ" />
    </div>
    <div class="header">
      <img class="header-logo" src="/assets/logos/full.png" alt="Drive iQ" />
    </div>
    <div class="centered">
      <div class="app-router">
        <ion-router-outlet [style]="themeStyle | safe: 'style'" [swipeGesture]="false"></ion-router-outlet>

        <div class="modal-backdrop" *ngIf="showError" (click)="hideError()">
          <div class="modal profile-info button" (click)="$event.stopPropagation()">
            <ion-button mode="ios" class="close" fill="clear" (click)="hideError()">
              <ion-icon src="/assets/svgs/close.svg" slot="icon-only"></ion-icon>
            </ion-button>

            <h3 class="noselect" translate>error.popup-title</h3>

            <p class="noselect text" translate>{{ showError.message }}</p>

            <ion-button mode="ios" class="footer-button" (click)="retryError()" fill="clear" *ngIf="showError.button" translate>{{ showError.button }}</ion-button>
          </div>
        </div>
      </div>
      <div class="desktop-tabs noselect" *ngIf="auth &&  auth.user">
        <div class="buttons">
          <ion-row class="ion-margin-bottom ion-margin-top" (click)="gotoCourses()" [ngClass]="{'active': router.url.substr(0,15) == '/members/course', 'disabled': isIntro || ! auth.user.setup_at}">
            <ion-icon item-left src="/assets/svgs/courses.svg"></ion-icon>
            <span margin-left translate>tab.courses</span>
          </ion-row>
          <ion-row class="ion-margin-bottom ion-margin-top" (click)="gotoGoals()" [ngClass]="{'active': router.url.substr(0,14) == '/members/goals', 'disabled': isIntro || ! auth.user.setup_at || ! auth.user.completed_at}">
            <ion-icon item-left src="/assets/svgs/goals.svg"></ion-icon>
            <span margin-left translate>tab.goals</span>
          </ion-row>
          <ion-row class="ion-margin-bottom ion-margin-top" (click)="gotoNudges()" [ngClass]="{'active': router.url.substr(0,14) == '/members/nudge', 'disabled': isIntro || ! auth.user.setup_at}">
            <ion-icon item-left src="/assets/svgs/nudges.svg"></ion-icon>
            <span margin-left translate>tab.nudges</span>
          </ion-row>
          <ion-row class="ion-margin-bottom ion-margin-top" (click)="gotoProfile()" [ngClass]="{'active': router.url.substr(0,16) == '/members/profile', 'disabled': isIntro || ! auth.user.setup_at}">
            <ion-icon item-left src="/assets/svgs/profile.svg"></ion-icon>
            <span margin-left translate>tab.profile</span>
          </ion-row>
        </div>
      </div>
    </div>
  </div>
</ion-app>
