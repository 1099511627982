import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { Storage } from '@ionic/storage';
import { BehaviorSubject, Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import * as Sentry from 'sentry-cordova';

import { DataService } from './data.service';
import { ApiService } from './api.service';
import { ErrorService } from './error.service';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  user     = null;
  company  = null;
  state = new BehaviorSubject(null);
  last_authorisation = null;

  constructor(private apiService: ApiService, private dataService: DataService, private errorService: ErrorService) { }

  init() {
    const authToken = this.dataService.getAuthToken();
    if (authToken) {
      this.check().subscribe();
    } else {
      this.state.next(false);
    }
  }

  update(name, email, password = null, newPassword = null) {
    this.dataService.setAuthEmail(email);
    const update = new Observable(observer => {
      this.apiService.update(name, email, password, newPassword)
        .subscribe(
          data => {
            this.check()
              .subscribe(
                data => {
                  observer.next(data);
                  observer.complete();
                },
                error => observer.error(error)
              );
          },
          error => observer.error(error)
        );
    })
    .pipe(take(1));
    return update;
  }

  check(attempt: number = 0) {
    const check = new Observable(observer => {
      this.apiService.me()
        .subscribe(
          data => {
            this.user = data.user;
            this.company = data.company;
            this.dataService.setLanguage(data.user.language);
            this.dataService.setAuthEmail(data.user.email);
            this.state.next((new Date).getTime());
            this.last_authorisation = (new Date()).getTime();

            observer.next(this.getAuth());
            observer.complete();
          },
          error => {
            if (!error.status) {
              if (! this.last_authorisation || this.last_authorisation <= (new Date()).getTime() - (60 * 60 * 24 * 14 * 1000)) {
                if (attempt > 2) {
                  this.dataService.setAuthToken(null);
                  this.user = null;
                  this.company = null;
                  this.state.next(false);

                  observer.error(error);
                  return;
                }

                this.errorService.show('error.cannot-reach', 'error.retry-connection', () => {
                  this.check(attempt + 1)
                    .subscribe(data => {
                      observer.next(data);
                      observer.complete();
                    }, err => {
                      observer.error(err);
                    });
                });
              } else {
                observer.next(this.getAuth());
                observer.complete();
              }
            }

            if (error.status == 401 || attempt > 2) {
              this.dataService.setAuthToken(null);
              this.user = null;
              this.company = null;
              this.state.next(false);

              observer.error(error);
              return;
            }

            this.errorService.show('error.cannot-reach', 'error.retry-connection', () => {
              this.check(attempt + 1)
                .subscribe(data => {
                  observer.next(data);
                  observer.complete();
                }, err => {
                  observer.error(err);
                });
            });
          }
        );
    })
    .pipe(take(1));
    return check;
  }

  confirm(token: string) {
    const confirm = new Observable(observer => {
      this.apiService.confirm(token)
        .subscribe(
          data => {
            observer.next(data);
            observer.complete();
          },
          error => observer.error(error)
        );
    })
    .pipe(take(1));
    return confirm;
  }

  login(email: string, password = null): Observable<any> {
    this.dataService.setAuthEmail(email);
    const login = new Observable(observer => {
      this.apiService.login(email, password)
        .subscribe(
          data => {
            if (! password) {
              observer.next(data);
              observer.complete();
              return;
            }

            this.dataService.setAuthToken('Bearer ' + data.access_token);

            this.check()
              .subscribe(
                data => {
                  observer.next(data);
                  observer.complete();
                },
                error => observer.error(error)
              );
          },
          error => observer.error(error)
        );
    })
    .pipe(take(1));
    return login;
  }

  forgotPassword(email: string): Observable<any> {
    this.dataService.setAuthEmail(email);
    const forgotpass = new Observable(observer => {
      this.apiService.forgotpass(email)
        .subscribe(
          data => {
            observer.next(data);
            observer.complete();
          },
          error => observer.error(error)
        );
    })
    .pipe(take(1));
    return forgotpass;
  }

  register(name: string, email: string, password: string, companyCode = null): Observable<any> {
    this.dataService.setAuthEmail(email);
    const register = new Observable(observer => {
      this.apiService.register(name, email, password, companyCode, this.dataService.getLanguage())
        .subscribe(
          data => {
            if (! data.access_token) {
              observer.next(data);
              observer.complete();
              return;
            }

            this.dataService.setAuthToken('Bearer ' + data.access_token);

            this.check()
              .subscribe(
                data => {
                  observer.next(data);
                  observer.complete();
                },
                error => observer.error(error)
              );
          },
          error => observer.error(error)
        );
    })
    .pipe(take(1));
    return register;
  }

  logout(): Observable<any> {
    const logout = new Observable(observer => {
      this.apiService.logout()
        .subscribe(
          data => {
            this.dataService.setAuthToken(null);

            this.user = null;
            this.company = null;
            this.state.next(false);

            observer.next(data);
            observer.complete();
          },
          error => observer.error(error)
        );
    })
    .pipe(take(1));
    return logout;
  }

  isAuthenticated() {
    return this.state.value ? true : false;
  }

  getAuth() {
    return {
      user: this.user,
      company: this.company,
    };
  }

  getToken() {
    return this.dataService.getAuthToken();
  }

  getEmail() {
    return this.dataService.getAuthEmail();
  }

  getName() {
    return this.dataService.getAuthName();
  }
}