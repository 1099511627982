import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';

import { BadgeComponent } from './badge/badge.component';
import { BackgroundComponent } from './background/background.component';

@NgModule({
  declarations: [
    BadgeComponent,
    BackgroundComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [
    BadgeComponent,
    BackgroundComponent
  ],
  schemas: [NO_ERRORS_SCHEMA]
})
export class ComponentsModule { }
