import { NgModule, ErrorHandler } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { HttpClientModule, HttpClient } from '@angular/common/http';
import { TranslateModule, TranslateLoader} from '@ngx-translate/core';
import { TranslateHttpLoader} from '@ngx-translate/http-loader';

import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { SocialSharing } from '@ionic-native/social-sharing/ngx';

import { PipesModule } from './pipes/pipes.module';
 
import { IonicStorageModule } from '@ionic/storage';

import { FirebaseX } from '@ionic-native/firebase-x/ngx';
import { Deeplinks } from '@ionic-native/deeplinks/ngx';

import { SentryErrorHandler } from './handlers/error.handler';
import * as Sentry from 'sentry-cordova';

import { ScreenOrientation } from '@ionic-native/screen-orientation/ngx';

import { Printer } from '@ionic-native/printer/ngx';

import { NgxSocialButtonModule, SocialServiceConfig } from "ngx-social-button";
export function getAuthServiceConfigs() {
  return new SocialServiceConfig()
    .addFacebook('498142814130863');
}

import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptor } from './http-interceptors/auth.interceptor';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment, CDN_HOST, SENTRY_DSN } from '../environments/environment';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, CDN_HOST + '/assets/i18n/', '.json?' + (new Date).getTime());
}

import { iosTransitionAnimation } from '@ionic/core/dist/collection/utils/transition/ios.transition';
import { Animation } from '@ionic/core';

export function transitionAnimation(foo: Animation, el: HTMLElement, opts: any): Promise<Animation> {
  return Promise.resolve(iosTransitionAnimation(el, opts));
}

Sentry.init({
  dsn: SENTRY_DSN,
  beforeSend: (event, hint) => {
    const error: any = hint.originalException;
    if (error && error.status && error.status == 401) {
      return null;
    }

    return event;
  },
});

import { ComponentsModule } from './components/components.module';

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    BrowserModule,
    IonicModule.forRoot({
      animated: false,
      navAnimation: transitionAnimation
    }), 
    AppRoutingModule,
    HttpClientModule,
    IonicStorageModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    }),
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    PipesModule,
    NgxSocialButtonModule,
    ComponentsModule
  ],
  providers: [
    { provide: ErrorHandler, useClass: environment.production ? SentryErrorHandler : ErrorHandler },
    SplashScreen,
    StatusBar,
    ScreenOrientation,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    FirebaseX,
    Deeplinks,
    InAppBrowser,
    Printer,
    SocialSharing,
    { provide: SocialServiceConfig, useFactory: getAuthServiceConfigs }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
