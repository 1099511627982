import * as tslib_1 from "tslib";
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { DataService } from './data.service';
import { API_HOST, API_CLIENT_ID, API_CLIENT_SECRET } from '../../environments/environment';
import { take } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./data.service";
var ApiService = /** @class */ (function () {
    function ApiService(http, dataService) {
        this.http = http;
        this.dataService = dataService;
        this.url = API_HOST;
    }
    ApiService.prototype.serialize = function (obj) {
        var str = [];
        for (var p in obj)
            if (obj.hasOwnProperty(p)) {
                str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
            }
        return str.join("&");
    };
    ApiService.prototype.delay = function (ms) {
        return new Promise(function (resolve) { return setTimeout(resolve, ms); });
    };
    ApiService.prototype.send = function (url, data, attempt) {
        var _this = this;
        if (data === void 0) { data = null; }
        if (attempt === void 0) { attempt = 0; }
        var request = this.http.get("" + this.url + url, {
            headers: {
                'X-Requested-With': 'XMLHttpRequest',
                'Accept': 'application/json',
                'Accept-Language': this.dataService.getLanguage('en'),
            },
        });
        if (data) {
            request = this.http.post("" + this.url + url, data, {
                headers: {
                    'X-Requested-With': 'XMLHttpRequest',
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Accept-Language': this.dataService.getLanguage('en'),
                },
            });
        }
        return new Observable(function (observer) {
            request.pipe(take(1))
                .subscribe(function (data) {
                observer.next(data);
                observer.complete();
            }, function (error) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                return tslib_1.__generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            console.log(error.status);
                            if (error.status || attempt > 2) {
                                observer.error(error);
                                return [2 /*return*/];
                            }
                            return [4 /*yield*/, this.delay(1000)];
                        case 1:
                            _a.sent();
                            this.send(url, data, attempt + 1)
                                .subscribe(function (data) {
                                observer.next(data);
                                observer.complete();
                            }, function (error) { return observer.error(error); });
                            return [2 /*return*/];
                    }
                });
            }); });
        })
            .pipe(take(1));
    };
    ApiService.prototype.languages = function () {
        return this.send('/languages');
    };
    ApiService.prototype.badges = function () {
        return this.send('/badges');
    };
    ApiService.prototype.badge = function (id) {
        return this.send('/badge/' + id);
    };
    ApiService.prototype.me = function () {
        return this.send('/me');
    };
    ApiService.prototype.invite = function (name, email) {
        return this.send('/invite', {
            name: name,
            email: email,
        });
    };
    ApiService.prototype.checkLogin = function (email) {
        return this.send('/login/check', {
            email: email,
        });
    };
    ApiService.prototype.update = function (name, email, password, newPassword) {
        if (password === void 0) { password = null; }
        if (newPassword === void 0) { newPassword = null; }
        return this.send('/me', {
            name: name,
            email: email,
            password: password,
            newPassword: newPassword,
        });
    };
    ApiService.prototype.login = function (email, password) {
        return this.send('/oauth/token', {
            grant_type: 'password',
            client_id: API_CLIENT_ID,
            client_secret: API_CLIENT_SECRET,
            username: email,
            password: password,
            scope: ''
        });
    };
    ApiService.prototype.resetpass = function (token, password) {
        if (password === void 0) { password = null; }
        return this.send('/resetpass/' + token, password != null ? { password: password } : null);
    };
    ApiService.prototype.confirm = function (token) {
        return this.send('/confirm/' + token);
    };
    ApiService.prototype.forgotpass = function (email) {
        return this.send('/forgotpass', { email: email });
    };
    ApiService.prototype.logout = function () {
        return this.send('/logout', { sent: new Date() });
    };
    ApiService.prototype.checkEmail = function (email) {
        return this.send('/login', {
            email: email
        });
    };
    ApiService.prototype.register = function (name, email, password, companyCode, language) {
        if (companyCode === void 0) { companyCode = null; }
        if (language === void 0) { language = 'en'; }
        return this.send('/register', {
            name: name,
            email: email,
            password: password,
            companyCode: companyCode,
            language: language
        });
    };
    ApiService.prototype.language = function (language) {
        return this.send('/language', { language: language });
    };
    ApiService.prototype.setupComplete = function () {
        return this.send('/setup/complete', { sent: new Date() });
    };
    ApiService.prototype.addDevice = function (token) {
        return this.send('/me/device', { token: token });
    };
    ApiService.prototype.courses = function () {
        return this.send('/courses');
    };
    ApiService.prototype.courseCategory = function (id, categoryId, data) {
        if (data === void 0) { data = null; }
        return this.send('/course/' + id + '/categories/' + categoryId, data);
    };
    ApiService.prototype.goals = function (id) {
        if (id === void 0) { id = null; }
        return this.send('/goals', id ? { id: id } : null);
    };
    ApiService.prototype.nudges = function (id) {
        if (id === void 0) { id = null; }
        return this.send('/nudges', id ? { id: id } : null);
    };
    ApiService.prototype.nudge = function (id, read) {
        if (id === void 0) { id = null; }
        if (read === void 0) { read = false; }
        return this.send('/nudge/' + id, read ? { read: read } : null);
    };
    ApiService.prototype.rate = function (rating, shared) {
        if (rating === void 0) { rating = null; }
        if (shared === void 0) { shared = false; }
        return this.send('/rate', {
            rating: rating,
            shared: shared
        });
    };
    ApiService.ngInjectableDef = i0.defineInjectable({ factory: function ApiService_Factory() { return new ApiService(i0.inject(i1.HttpClient), i0.inject(i2.DataService)); }, token: ApiService, providedIn: "root" });
    return ApiService;
}());
export { ApiService };
