import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';

const routes: Routes = [
  { path: '', redirectTo: 'members', pathMatch: 'full' },
  { path: 'language', loadChildren: './pages/language/language.module#LanguagePageModule' },
  { path: 'check', loadChildren: './pages/check/check.module#CheckPageModule' },
  { path: 'confirm/:token', loadChildren: './pages/confirm/confirm.module#ConfirmPageModule' },
  { path: 'login', loadChildren: './pages/login/login.module#LoginPageModule' },
  { path: 'forgotpass', loadChildren: './pages/forgotpass/forgotpass.module#ForgotpassPageModule' },
  { path: 'register', loadChildren: './pages/register/register.module#RegisterPageModule' },
  { path: 'resetpass/:token', loadChildren: './pages/resetpass/resetpass.module#ResetpassPageModule' },
  {
    path: 'setup',
    canActivate: [AuthGuard],
    loadChildren: './pages/setup/setup.module#SetupPageModule'
  },
  {
    path: 'members',
    canActivate: [AuthGuard],
    loadChildren: './pages/members/members.module#MembersPageModule'
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
