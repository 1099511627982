import * as tslib_1 from "tslib";
import { LoadingController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "@ionic/angular";
import * as i2 from "@ngx-translate/core";
var ErrorService = /** @class */ (function () {
    function ErrorService(loadingController, translate) {
        this.loadingController = loadingController;
        this.translate = translate;
        this.state = new BehaviorSubject(false);
        this.message = null;
        this.button = null;
        this.callback = null;
    }
    ErrorService.prototype.getMessage = function () {
        return this.message;
    };
    ErrorService.prototype.getButton = function () {
        return this.button;
    };
    ErrorService.prototype.click = function () {
        this.hide();
        if (!this.callback) {
            return;
        }
        this.callback();
    };
    ErrorService.prototype.show = function (message, button, callback) {
        if (button === void 0) { button = null; }
        if (callback === void 0) { callback = null; }
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                if (this.state.value) {
                    this.hide();
                }
                this.message = message;
                this.button = button;
                this.callback = callback;
                this.state.next(true);
                return [2 /*return*/];
            });
        });
    };
    ErrorService.prototype.hide = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                this.state.next(false);
                return [2 /*return*/];
            });
        });
    };
    ErrorService.ngInjectableDef = i0.defineInjectable({ factory: function ErrorService_Factory() { return new ErrorService(i0.inject(i1.LoadingController), i0.inject(i2.TranslateService)); }, token: ErrorService, providedIn: "root" });
    return ErrorService;
}());
export { ErrorService };
