import { BehaviorSubject } from 'rxjs';
import { DataService } from './data.service';
import * as i0 from "@angular/core";
import * as i1 from "./data.service";
var ThemeService = /** @class */ (function () {
    function ThemeService(dataService) {
        this.dataService = dataService;
        this.state = new BehaviorSubject(null);
        this.backgroundType = 'clean';
        this.tempBlur = null;
        this.backgrounds = [
            { id: 1, color: '#989898' },
            { id: 2, color: '#CF103E' },
            { id: 3, color: '#F3950B' },
            { id: 4, color: '#DDCD0B' },
            { id: 5, color: '#3DAA3D' },
        ];
        this.themes = [
            { id: 1, backgroundId: 1, name: 'Corporate', color1: '#88BCBA', color2: '#234D54', color3: '#112D31', color4: '#4E4B41', color5: '#6E685A' },
            { id: 2, backgroundId: 1, name: 'Dynamic', color1: '#2E4455', color2: '#660523', color3: '#DA7C93', color4: '#367071', color5: '#193333' },
            { id: 3, backgroundId: 2, name: 'Morning', color1: '#75C9F7', color2: '#F99DB3', color3: '#75C9F7', color4: '#F8DE9F', color5: '#FEFFB2' },
            { id: 4, backgroundId: 2, name: 'Auburn', color1: '#F9A5BA', color2: '#F8914F', color3: '#EC5531', color4: '#D34438', color5: '#92253C' },
            { id: 5, backgroundId: 3, name: 'Cheerful', color1: '#DD6600', color2: '#F4C500', color3: '#A8E49C', color4: '#C4F4A6', color5: '#F5FED3' },
            { id: 6, backgroundId: 3, name: 'Creative', color1: '#338380', color2: '#F0DFB4', color3: '#F6EB75', color4: '#97D8F8', color5: '#72BBE6' },
            { id: 7, backgroundId: 4, name: 'Invigorate', color1: '#03E7F2', color2: '#6CBED6', color3: '#578798', color4: '#6CDDDD', color5: '#338380' },
            { id: 8, backgroundId: 4, name: 'Energetic', color1: '#B381C6', color2: '#CBCBCB', color3: '#76BE98', color4: '#338380', color5: '#8B7E91' },
            { id: 9, backgroundId: 5, name: 'Clean', color1: '#8CF299', color2: '#267B77', color3: '#2BAFA9', color4: '#DDEEEE', color5: '#EDF297' },
            { id: 10, backgroundId: 5, name: 'Vibrant', color1: '#E84747', color2: '#6DC4CD', color3: '#E5C71C', color4: '#98C9EE', color5: '#CDE8F1' },
        ];
    }
    ThemeService.prototype.getBackgroundType = function () {
        return this.backgroundType;
    };
    ThemeService.prototype.setBackgroundType = function (bgType) {
        this.backgroundType = bgType;
        this.state.next((new Date).getTime());
        return true;
    };
    ThemeService.prototype.getBackgrounds = function () {
        return this.backgrounds;
    };
    ThemeService.prototype.getThemes = function (backgroundId) {
        return this.themes.filter(function (theme) { return theme.backgroundId == backgroundId; });
    };
    ThemeService.prototype.getTheme = function () {
        var theme = this.dataService.getTheme();
        return {
            background: this.backgrounds.find(function (b) { return b.id == theme.background; }) || this.backgrounds[0],
            theme: this.themes.find(function (t) { return t.id == theme.theme; }) || this.themes[0],
            blur: theme.blur != null ? theme.blur : 0
        };
    };
    ThemeService.prototype.getThemeStyle = function () {
        var theme = this.getTheme();
        var styles = [];
        styles.push('--themeColor1: ' + theme.theme.color1);
        styles.push('--themeColor2: ' + theme.theme.color2);
        styles.push('--themeColor3: ' + theme.theme.color3);
        styles.push('--themeColor4: ' + theme.theme.color4);
        styles.push('--themeColor5: ' + theme.theme.color5);
        return styles.join('; ');
    };
    ThemeService.prototype.setBackground = function (id) {
        var background = this.backgrounds.find(function (b) { return b.id == id; });
        if (!background) {
            return false;
        }
        var theme = this.dataService.getTheme();
        theme.background = id;
        theme.theme = null;
        this.dataService.setTheme(theme);
        this.tempBlur = theme.blur != null ? theme.blur : 8;
        this.state.next((new Date).getTime());
    };
    ThemeService.prototype.setBlur = function (amount) {
        if (amount < 0 || amount > 15) {
            return false;
        }
        var theme = this.dataService.getTheme();
        theme.blur = amount;
        this.dataService.setTheme(theme);
        this.tempBlur = null;
        this.state.next((new Date).getTime());
    };
    ThemeService.prototype.getTempBlur = function () {
        return this.tempBlur;
    };
    ThemeService.prototype.setTempBlur = function (amount) {
        if (amount < 0 || amount > 15) {
            return false;
        }
        this.tempBlur = amount;
        this.state.next((new Date).getTime());
    };
    ThemeService.prototype.setTheme = function (id) {
        var theme = this.dataService.getTheme();
        var t = this.themes.find(function (t) { return t.id == id; });
        if (!t || t.backgroundId != theme.background) {
            return false;
        }
        theme.theme = id;
        this.dataService.setTheme(theme);
        this.tempBlur = null;
        this.state.next((new Date).getTime());
    };
    ThemeService.ngInjectableDef = i0.defineInjectable({ factory: function ThemeService_Factory() { return new ThemeService(i0.inject(i1.DataService)); }, token: ThemeService, providedIn: "root" });
    return ThemeService;
}());
export { ThemeService };
