import { Component, OnInit } from '@angular/core';

import { FullThemeModel, ThemeService } from '../../services/theme.service';

@Component({
  selector: 'app-background',
  templateUrl: './background.component.html',
  styleUrls: ['./background.component.scss'],
})
export class BackgroundComponent implements OnInit {

  themeStyle: string = '';
  themeType: string = '';
  themeTempBlur: number = null;

  theme: FullThemeModel = {
    background: null,
    theme: {
      id: null,
      backgroundId: null,
      color1: null,
      color2: null,
      color3: null,
      color4: null,
      color5: null,
    },
    blur: null
  };

  themeSub = null;

  constructor(private themeService: ThemeService) { }

  ngOnInit() {
    this.themeSub = this.themeService.state
      .subscribe(() => {
        this.theme = this.themeService.getTheme();
        this.themeTempBlur = this.themeService.getTempBlur();
        this.themeType = this.themeService.getBackgroundType();
      });
  }

  ngOnDestroy() {
    this.themeSub && this.themeSub.unsubscribe();
  }

}
