import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';
import { DataService } from './data.service';
import { AuthenticationService } from './authentication.service';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {
  ready = new BehaviorSubject(false);

  defaultLang: string = 'en';
  languages = [];

  constructor(private authService: AuthenticationService, private apiService: ApiService, private dataService: DataService, private translate: TranslateService) { }

  init() {
    this.apiService.languages()
      .subscribe(data => {
        this.languages = [];
        let languageKeys = [];

        data.forEach(language => {
          this.languages.push({
            text: language.name,
            value: language.key,
          });

          languageKeys.push(language.key);
        });

        this.translate.addLangs(languageKeys);
        this.translate.setDefaultLang(languageKeys[0]);

        const browserLang = this.translate.getBrowserLang();
        languageKeys.forEach(lang => {
          if (browserLang.match(new RegExp(lang, 'i'))) {
            this.defaultLang = lang;
          }
        });

        let language = this.dataService.getLanguage(this.defaultLang);
        this.translate.use(language)
          .subscribe(() => {
            this.ready.next(true);
          });
      });
  }

  getLanguages() {
    return this.languages;
  }

  getLanguage() {
    const language = this.dataService.getLanguage();
    return this.languages.find(l => l.value == language);
  }

  getLanguageKey() {
    console.log(this.dataService.getLanguage() || this.defaultLang);
    return this.dataService.getLanguage() || this.defaultLang;
  }

  setLanguage(lang) {
    this.translate.use(lang);
    this.dataService.setLanguage(lang);
    if (this.authService.isAuthenticated()) {
      this.authService.check().subscribe();
    }
  }
}
