import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';
import { DataService } from './data.service';
import { AuthenticationService } from './authentication.service';
import { ApiService } from './api.service';
import * as i0 from "@angular/core";
import * as i1 from "./authentication.service";
import * as i2 from "./api.service";
import * as i3 from "./data.service";
import * as i4 from "@ngx-translate/core";
var LanguageService = /** @class */ (function () {
    function LanguageService(authService, apiService, dataService, translate) {
        this.authService = authService;
        this.apiService = apiService;
        this.dataService = dataService;
        this.translate = translate;
        this.ready = new BehaviorSubject(false);
        this.defaultLang = 'en';
        this.languages = [];
    }
    LanguageService.prototype.init = function () {
        var _this = this;
        this.apiService.languages()
            .subscribe(function (data) {
            _this.languages = [];
            var languageKeys = [];
            data.forEach(function (language) {
                _this.languages.push({
                    text: language.name,
                    value: language.key,
                });
                languageKeys.push(language.key);
            });
            _this.translate.addLangs(languageKeys);
            _this.translate.setDefaultLang(languageKeys[0]);
            var browserLang = _this.translate.getBrowserLang();
            languageKeys.forEach(function (lang) {
                if (browserLang.match(new RegExp(lang, 'i'))) {
                    _this.defaultLang = lang;
                }
            });
            var language = _this.dataService.getLanguage(_this.defaultLang);
            _this.translate.use(language)
                .subscribe(function () {
                _this.ready.next(true);
            });
        });
    };
    LanguageService.prototype.getLanguages = function () {
        return this.languages;
    };
    LanguageService.prototype.getLanguage = function () {
        var language = this.dataService.getLanguage();
        return this.languages.find(function (l) { return l.value == language; });
    };
    LanguageService.prototype.getLanguageKey = function () {
        console.log(this.dataService.getLanguage() || this.defaultLang);
        return this.dataService.getLanguage() || this.defaultLang;
    };
    LanguageService.prototype.setLanguage = function (lang) {
        this.translate.use(lang);
        this.dataService.setLanguage(lang);
        if (this.authService.isAuthenticated()) {
            this.authService.check().subscribe();
        }
    };
    LanguageService.ngInjectableDef = i0.defineInjectable({ factory: function LanguageService_Factory() { return new LanguageService(i0.inject(i1.AuthenticationService), i0.inject(i2.ApiService), i0.inject(i3.DataService), i0.inject(i4.TranslateService)); }, token: LanguageService, providedIn: "root" });
    return LanguageService;
}());
export { LanguageService };
