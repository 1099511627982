import * as tslib_1 from "tslib";
import { NgZone } from '@angular/core';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { Router } from '@angular/router';
import { Platform } from '@ionic/angular';
import { ScreenOrientation } from '@ionic-native/screen-orientation/ngx';
import { Deeplinks } from '@ionic-native/deeplinks/ngx';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { DataService } from './services/data.service';
import { LanguageService } from './services/language.service';
import { AuthenticationService } from './services/authentication.service';
import { FirebaseService } from './services/firebase.service';
import { ErrorService } from './services/error.service';
import { ThemeService } from './services/theme.service';
var AppComponent = /** @class */ (function () {
    function AppComponent(platform, splashScreen, statusBar, dataService, languageService, authService, themeService, router, firebaseService, deeplinks, screenOrientation, ngZone, errorService) {
        this.platform = platform;
        this.splashScreen = splashScreen;
        this.statusBar = statusBar;
        this.dataService = dataService;
        this.languageService = languageService;
        this.authService = authService;
        this.themeService = themeService;
        this.router = router;
        this.firebaseService = firebaseService;
        this.deeplinks = deeplinks;
        this.screenOrientation = screenOrientation;
        this.ngZone = ngZone;
        this.errorService = errorService;
        this.loading = true;
        this.redirect = true;
        this.previousUri = null;
        this.themeStyle = '';
        this.themeType = '';
        this.themeTempBlur = null;
        this.showError = false;
        this.isIntro = false;
        this.auth = {
            user: {
                name: null,
                email: null,
                setup_at: null,
                completed_at: null,
            },
        };
        this.theme = {
            background: null,
            theme: {
                id: null,
                backgroundId: null,
                color1: null,
                color2: null,
                color3: null,
                color4: null,
                color5: null,
            },
            blur: null
        };
        if (window.location.pathname != '/') {
            this.previousUri = window.location.pathname;
        }
        this.initializeApp();
    }
    AppComponent.prototype.initializeApp = function () {
        var _this = this;
        this.platform.ready().then(function () {
            if (_this.platform.is('cordova')) {
                var ionApp_1 = document.getElementsByTagName("ion-app")[0];
                // console.info(ionApp ? "got app" : "not got app");
                window.addEventListener('keyboardDidShow', function (event) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                    var kbHeight, viewportHeight, focusedElement, inputFieldOffsetFromBottomViewPort, inputScrollPixels;
                    return tslib_1.__generator(this, function (_a) {
                        console.info('keyboardDidShow'); // <----- don't even get to here
                        kbHeight = event["keyboardHeight"];
                        viewportHeight = window.innerHeight;
                        focusedElement = document.activeElement;
                        if (!focusedElement) {
                            console.warn('windowsKeyboardWorkaround: Could not get focused input');
                            return [2 /*return*/];
                        }
                        inputFieldOffsetFromBottomViewPort = viewportHeight - focusedElement.getBoundingClientRect().bottom;
                        inputScrollPixels = kbHeight - inputFieldOffsetFromBottomViewPort;
                        // console.log(inputScrollPixels);
                        // Set margin to give space for native keyboard.
                        // ionApp.style["margin-bottom"] = kbHeight.toString() + "px";
                        // But this diminishes ion-content and may hide the input field...
                        if (inputScrollPixels > 0) {
                            ionApp_1.style["margin-top"] = '-' + (40 + inputScrollPixels) + 'px';
                        }
                        return [2 /*return*/];
                    });
                }); });
                window.addEventListener('keyboardDidHide', function () {
                    // Move ion-app down again
                    // Scroll not necessary.
                    ionApp_1.style["margin-top"] = "0px";
                });
                _this.deeplinks.route({
                    '/members/courses': true,
                    '/members/course/:course': true,
                    '/members/nudges': true,
                    '/nudges/:nudge': true,
                    '/members/profile/personal': true,
                    '/members/profile/shared': true,
                    '/resetpass/:token': true,
                    '/confirm/:token': true,
                }).subscribe(function (match) {
                    _this.ngZone.run(function () {
                        _this.router.navigate([match.$link.path]);
                    });
                }, function (nomatch) { });
                _this.screenOrientation.lock(_this.screenOrientation.ORIENTATIONS.PORTRAIT);
                _this.statusBar.hide();
            }
            _this.errorService.state
                .subscribe(function (show) {
                if (show) {
                    _this.showError = {
                        message: _this.errorService.getMessage(),
                        button: _this.errorService.getButton(),
                    };
                }
                else {
                    _this.showError = false;
                }
            });
            _this.dataService.ready
                .subscribe(function (ready) {
                if (!ready) {
                    return;
                }
                _this.router.events.subscribe(function (val) {
                    _this.isIntro = !_this.dataService.getIntro();
                });
                console.log('Ready');
                _this.languageService.ready
                    .subscribe(function (ready) {
                    if (!ready) {
                        return;
                    }
                    _this.themeService.state
                        .subscribe(function () {
                        _this.themeStyle = _this.themeService.getThemeStyle();
                        _this.theme = _this.themeService.getTheme();
                        _this.themeTempBlur = _this.themeService.getTempBlur();
                        _this.themeType = _this.themeService.getBackgroundType();
                    });
                    _this.authService.state
                        .subscribe(function (state) {
                        if (state === null) {
                            return;
                        }
                        _this.auth = _this.authService.getAuth();
                        var language = _this.dataService.getLanguage();
                        if (!language) {
                            _this.router.navigate(['language']);
                            setTimeout(function () {
                                _this.loading = false;
                                if (_this.platform.is('cordova')) {
                                    _this.splashScreen.hide();
                                }
                            }, 200);
                            return;
                        }
                        if (!_this.authService.isAuthenticated()) {
                            var hasEndpoints_1 = ['/check', '/register', '/login', '/forgotpass', '/resetpass/', '/confirm/'].filter(function (uri) {
                                return _this.previousUri && _this.previousUri.startsWith(uri);
                            });
                            if (!_this.previousUri || !hasEndpoints_1.length) {
                                _this.router.navigate(['check']);
                            }
                            setTimeout(function () {
                                _this.loading = false;
                                if (_this.platform.is('cordova')) {
                                    _this.splashScreen.hide();
                                }
                            }, 200);
                            return;
                        }
                        _this.dataService.setAuthName(_this.auth.user.name);
                        _this.dataService.setAuthEmail(_this.auth.user.email);
                        if (_this.platform.is('cordova')) {
                            _this.firebaseService.init();
                        }
                        if (!_this.redirect) {
                            setTimeout(function () {
                                _this.loading = false;
                                if (_this.platform.is('cordova')) {
                                    _this.splashScreen.hide();
                                }
                            }, 200);
                            return;
                        }
                        _this.redirect = false;
                        var hasEndpoints = ['/check', '/register', '/login', '/forgotpass', '/resetpass/', '/confirm/'].filter(function (uri) {
                            return _this.previousUri && _this.previousUri.startsWith(uri);
                        });
                        if (_this.previousUri && !hasEndpoints.length) {
                            _this.router.navigate([_this.previousUri]);
                            setTimeout(function () {
                                _this.loading = false;
                                if (_this.platform.is('cordova')) {
                                    _this.splashScreen.hide();
                                }
                            }, 200);
                            return;
                        }
                        if (!_this.auth.user.setup_at) {
                            _this.router.navigate(['setup', 'invite']);
                            if (_this.platform.is('cordova')) {
                                _this.splashScreen.hide();
                            }
                            return;
                        }
                        if (!_this.dataService.getIntro()) {
                            _this.router.navigate(['setup', 'intro']);
                            _this.loading = false;
                            if (_this.platform.is('cordova')) {
                                _this.splashScreen.hide();
                            }
                            return;
                        }
                        var theme = _this.dataService.getTheme();
                        if (!theme.background || !theme.theme) {
                            _this.router.navigate(['setup', 'background']);
                            setTimeout(function () {
                                _this.loading = false;
                                if (_this.platform.is('cordova')) {
                                    _this.splashScreen.hide();
                                }
                            }, 200);
                            return;
                        }
                        _this.router.navigate(['members']);
                        setTimeout(function () {
                            _this.loading = false;
                            if (_this.platform.is('cordova')) {
                                _this.splashScreen.hide();
                            }
                        }, 200);
                    });
                    _this.authService.init();
                });
                _this.languageService.init();
            });
        });
    };
    AppComponent.prototype.gotoCourses = function () {
        if (this.isIntro || !this.auth.user || !this.auth.user.setup_at) {
            return;
        }
        this.router.navigate(['members', 'courses']);
    };
    AppComponent.prototype.gotoGoals = function () {
        if (this.isIntro || !this.auth.user || !this.auth.user.setup_at || !this.auth.user.completed_at) {
            return;
        }
        this.router.navigate(['members', 'goals']);
    };
    AppComponent.prototype.gotoNudges = function () {
        if (this.isIntro || !this.auth.user || !this.auth.user.setup_at) {
            return;
        }
        this.router.navigate(['members', 'nudges']);
    };
    AppComponent.prototype.gotoProfile = function () {
        if (this.isIntro || !this.auth.user || !this.auth.user.setup_at) {
            return;
        }
        this.router.navigate(['members', 'profile']);
    };
    AppComponent.prototype.retryError = function () {
        this.errorService.click();
    };
    AppComponent.prototype.hideError = function () {
        this.errorService.hide();
    };
    return AppComponent;
}());
export { AppComponent };
