/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./background.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./background.component";
import * as i4 from "../../services/theme.service";
var styles_BackgroundComponent = [i0.styles];
var RenderType_BackgroundComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_BackgroundComponent, data: {} });
export { RenderType_BackgroundComponent as RenderType_BackgroundComponent };
export function View_BackgroundComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "background"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "image"]], null, null, null, null, null)), i1.ɵdid(2, 278528, null, 0, i2.NgStyle, [i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngStyle: [0, "ngStyle"] }, null), i1.ɵpod(3, { "background-color": 0, "background-image": 1, "filter": 2, "-webkit-filter": 3 })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 3, 0, ((_co.theme.background && _co.theme.background.color) ? _co.theme.background.color : ""), ((_co.theme.background && _co.theme.background.src) ? (("url(" + _co.theme.background.src) + ")") : ""), ((_co.theme.background && _co.theme.background.src) ? ((_co.themeTempBlur != null) ? (("blur(" + _co.themeTempBlur) + "px)") : (_co.theme.blur ? (("blur(" + _co.theme.blur) + "px)") : "")) : ""), ((_co.theme.background && _co.theme.background.src) ? ((_co.themeTempBlur >= 0) ? (("blur(" + _co.themeTempBlur) + "px)") : (_co.theme.blur ? (("blur(" + _co.theme.blur) + "px)") : "")) : "")); _ck(_v, 2, 0, currVal_0); }, null); }
export function View_BackgroundComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-background", [], null, null, null, View_BackgroundComponent_0, RenderType_BackgroundComponent)), i1.ɵdid(1, 245760, null, 0, i3.BackgroundComponent, [i4.ThemeService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var BackgroundComponentNgFactory = i1.ɵccf("app-background", i3.BackgroundComponent, View_BackgroundComponent_Host_0, {}, {}, []);
export { BackgroundComponentNgFactory as BackgroundComponentNgFactory };
