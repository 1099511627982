import * as Sentry from 'sentry-cordova';
var SentryErrorHandler = /** @class */ (function () {
    function SentryErrorHandler() {
    }
    SentryErrorHandler.prototype.handleError = function (error) {
        try {
            Sentry.captureException(error.originalError || error);
        }
        catch (e) {
            console.error(e);
        }
    };
    return SentryErrorHandler;
}());
export { SentryErrorHandler };
