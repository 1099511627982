import * as tslib_1 from "tslib";
import { Platform } from '@ionic/angular';
import { Storage } from '@ionic/storage';
import { BehaviorSubject } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "@ionic/storage";
import * as i2 from "@ionic/angular";
var TOKEN_KEY = 'AUTH_TOKEN';
var EMAIL_KEY = 'AUTH_EMAIL';
var NAME_KEY = 'AUTH_NAME';
var LANG_KEY = 'SELECTED_LANGUAGE';
var THEME_KEY = 'SELECTED_THEME';
var INTRO_KEY = 'SHOW_INTRO';
var NOTIFICATION_REQUEST_KEY = 'SHOW_NOTIFICATION_REQUEST';
var PROFILE_INFO_SHOWN_KEY = 'SHOW_PROFILE_INFO';
var DataService = /** @class */ (function () {
    function DataService(storage, platform) {
        var _this = this;
        this.storage = storage;
        this.platform = platform;
        this.ready = new BehaviorSubject(false);
        this.authToken = null;
        this.authName = null;
        this.authEmail = null;
        this.language = null;
        this.notificationRequested = null;
        this.profileInfoShown = null;
        this.intro = null;
        this.theme = {
            background: null,
            theme: null,
            blur: null,
        };
        this.platform.ready().then(function () { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                this.getStorage();
                return [2 /*return*/];
            });
        }); });
    }
    DataService.prototype.getStorage = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var res;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.storage.get(TOKEN_KEY)];
                    case 1:
                        res = _a.sent();
                        if (res) {
                            this.authToken = res;
                        }
                        return [4 /*yield*/, this.storage.get(EMAIL_KEY)];
                    case 2:
                        res = _a.sent();
                        if (res) {
                            this.authEmail = res;
                        }
                        return [4 /*yield*/, this.storage.get(NAME_KEY)];
                    case 3:
                        res = _a.sent();
                        if (res) {
                            this.authName = res;
                        }
                        return [4 /*yield*/, this.storage.get(LANG_KEY)];
                    case 4:
                        res = _a.sent();
                        if (res) {
                            if (res == ' English ') {
                                res = 'en';
                                this.storage.set(LANG_KEY, res);
                            }
                            this.language = res;
                        }
                        return [4 /*yield*/, this.storage.get(INTRO_KEY)];
                    case 5:
                        res = _a.sent();
                        if (res) {
                            this.intro = res;
                        }
                        return [4 /*yield*/, this.storage.get(THEME_KEY)];
                    case 6:
                        res = _a.sent();
                        if (res) {
                            this.theme = res;
                        }
                        return [4 /*yield*/, this.storage.get(NOTIFICATION_REQUEST_KEY)];
                    case 7:
                        res = _a.sent();
                        if (res) {
                            this.notificationRequested = res;
                        }
                        return [4 /*yield*/, this.storage.get(PROFILE_INFO_SHOWN_KEY)];
                    case 8:
                        res = _a.sent();
                        if (res) {
                            this.profileInfoShown = res;
                        }
                        this.ready.next(true);
                        return [2 /*return*/];
                }
            });
        });
    };
    // Auth Token
    DataService.prototype.getAuthToken = function () {
        return this.authToken;
    };
    DataService.prototype.setAuthToken = function (token) {
        this.storage.set(TOKEN_KEY, token);
        this.authToken = token;
    };
    // Auth Name
    DataService.prototype.getAuthName = function () {
        return this.authName;
    };
    DataService.prototype.setAuthName = function (name) {
        this.storage.set(NAME_KEY, name);
        this.authEmail = name;
    };
    // Auth Email
    DataService.prototype.getAuthEmail = function () {
        return this.authEmail;
    };
    DataService.prototype.setAuthEmail = function (email) {
        this.storage.set(EMAIL_KEY, email);
        this.authEmail = email;
    };
    // Language
    DataService.prototype.getLanguage = function (def) {
        if (def === void 0) { def = null; }
        return this.language || def;
    };
    DataService.prototype.setLanguage = function (language) {
        this.storage.set(LANG_KEY, language);
        this.language = language;
    };
    // Notification Request
    DataService.prototype.getNotificationRequested = function () {
        return this.notificationRequested;
    };
    DataService.prototype.setNotificationRequested = function (requested) {
        this.storage.set(NOTIFICATION_REQUEST_KEY, requested);
        this.notificationRequested = requested;
    };
    // Profile Info Shown
    DataService.prototype.getProfileInfoShown = function () {
        return this.profileInfoShown;
    };
    DataService.prototype.setProfileInfoShown = function (shown) {
        this.storage.set(PROFILE_INFO_SHOWN_KEY, shown);
        this.profileInfoShown = shown;
    };
    // Theme
    DataService.prototype.getTheme = function () {
        return this.theme;
    };
    DataService.prototype.setTheme = function (theme) {
        this.storage.set(THEME_KEY, theme);
        this.theme = theme;
    };
    // Intro
    DataService.prototype.getIntro = function () {
        return this.intro;
    };
    DataService.prototype.setIntro = function (intro) {
        this.storage.set(INTRO_KEY, intro);
        this.intro = intro;
    };
    DataService.ngInjectableDef = i0.defineInjectable({ factory: function DataService_Factory() { return new DataService(i0.inject(i1.Storage), i0.inject(i2.Platform)); }, token: DataService, providedIn: "root" });
    return DataService;
}());
export { DataService };
