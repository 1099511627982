import { Injectable } from '@angular/core';
import { FirebaseX } from '@ionic-native/firebase-x/ngx';
import { ApiService } from './api.service';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FirebaseService {
  hasPermission = new BehaviorSubject(null);
  newMessage = new BehaviorSubject(null);

  constructor(private firebaseX: FirebaseX, private apiService: ApiService) { }

  init() {
    this.firebaseX.hasPermission()
      .then(hasPermission => {
        this.hasPermission.next(hasPermission);

        if (hasPermission) {
          this.firebaseX.onTokenRefresh()
            .subscribe((token: string) => {
              this.apiService.addDevice(token)
                .subscribe();
            });

          this.firebaseX.onMessageReceived()
            .subscribe(data => {
              this.newMessage.next((new Date).getTime());
              console.log(`FCM message: ${data}`);
            });
        }
      });
  }

  requestNotifications() {
    this.firebaseX.grantPermission()
      .then(() => {
        this.init();
      });
  }
}
