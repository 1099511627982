import { Component, NgZone } from '@angular/core';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { Router } from '@angular/router';
import { Platform } from '@ionic/angular';
import { ScreenOrientation } from '@ionic-native/screen-orientation/ngx';
import { Deeplinks } from '@ionic-native/deeplinks/ngx';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { DataService } from './services/data.service';
import { LanguageService } from './services/language.service';
import { AuthenticationService } from './services/authentication.service';
import { FirebaseService } from './services/firebase.service';
import { ErrorService } from './services/error.service';
import { ThemeService, FullThemeModel } from './services/theme.service';

import { PersonalPage } from './pages/members/pages/profile/pages/personal/personal.page';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html'
})
export class AppComponent {
  loading: boolean = true;
  redirect: boolean = true;
  previousUri: string = null;
  themeStyle: string = '';
  themeType: string = '';
  themeTempBlur: number = null;
  showError: any = false;

  isIntro = false;

  auth = {
    user: {
      name: null,
      email: null,
      setup_at: null,
      completed_at: null,
    },
  };

  theme: FullThemeModel = {
    background: null,
    theme: {
      id: null,
      backgroundId: null,
      color1: null,
      color2: null,
      color3: null,
      color4: null,
      color5: null,
    },
    blur: null
  };

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private dataService: DataService,
    private languageService: LanguageService,
    private authService: AuthenticationService,
    private themeService: ThemeService,
    private router: Router,
    private firebaseService: FirebaseService,
    private deeplinks: Deeplinks,
    private screenOrientation: ScreenOrientation,
    private ngZone: NgZone,
    private errorService: ErrorService
  ) {
    if (window.location.pathname != '/') {
      this.previousUri = window.location.pathname;
    }
    this.initializeApp();
  }

  initializeApp() {
    this.platform.ready().then(() => {
      if (this.platform.is('cordova')) {
        const ionApp = document.getElementsByTagName("ion-app")[0];
        // console.info(ionApp ? "got app" : "not got app");

        window.addEventListener('keyboardDidShow', async (event) => {
          console.info('keyboardDidShow'); // <----- don't even get to here

          // Move ion-app up, to give room for keyboard
          let kbHeight = event["keyboardHeight"];
          let viewportHeight = window.innerHeight;

          // console.info(`viewportHeight: ${viewportHeight}`);

          const focusedElement = document.activeElement;
          if (!focusedElement) {
            console.warn('windowsKeyboardWorkaround: Could not get focused input');
            return;
          }

          let inputFieldOffsetFromBottomViewPort = viewportHeight - focusedElement.getBoundingClientRect().bottom;
          let inputScrollPixels = kbHeight - inputFieldOffsetFromBottomViewPort;

          // console.log(inputScrollPixels);

          // Set margin to give space for native keyboard.
          // ionApp.style["margin-bottom"] = kbHeight.toString() + "px";

          // But this diminishes ion-content and may hide the input field...
          if (inputScrollPixels > 0) {
             ionApp.style["margin-top"] = '-' + (40 + inputScrollPixels) + 'px';
          }
        });
        window.addEventListener('keyboardDidHide', () => {
          // Move ion-app down again
          // Scroll not necessary.
          ionApp.style["margin-top"] = "0px";
        });

        this.deeplinks.route({
            '/members/courses': true,
            '/members/course/:course': true,
            '/members/nudges': true,
            '/nudges/:nudge': true,
            '/members/profile/personal': true,
            '/members/profile/shared': true,
            '/resetpass/:token': true,
            '/confirm/:token': true,
          }).subscribe(match => {
            this.ngZone.run(() => {
              this.router.navigate([match.$link.path]);
            });
          }, nomatch => {});

        this.screenOrientation.lock(this.screenOrientation.ORIENTATIONS.PORTRAIT);
        this.statusBar.hide();
      }

      this.errorService.state
        .subscribe(show => {
          if (show) {
            this.showError = {
              message: this.errorService.getMessage(),
              button: this.errorService.getButton(),
            };
          } else {
            this.showError = false;
          }
        });

      this.dataService.ready
        .subscribe(ready => {
          if (!ready) {
            return;
          }

          this.router.events.subscribe(val => {
            this.isIntro = ! this.dataService.getIntro();
          });

          console.log('Ready');

          this.languageService.ready
            .subscribe(ready => {
              if (! ready) {
                return;
              }

              this.themeService.state
                .subscribe(() => {
                  this.themeStyle = this.themeService.getThemeStyle();
                  this.theme = this.themeService.getTheme();
                  this.themeTempBlur = this.themeService.getTempBlur();
                  this.themeType = this.themeService.getBackgroundType();
                });

              this.authService.state
                .subscribe(state => {
                  if (state === null) {
                    return;
                  }
                  this.auth = this.authService.getAuth();

                  const language = this.dataService.getLanguage();
                  if (! language) {
                    this.router.navigate(['language']);
                    setTimeout(() => {
                      this.loading = false;
                      if (this.platform.is('cordova')) {
                        this.splashScreen.hide();
                      }
                    }, 200);
                    return;
                  }

                  if (! this.authService.isAuthenticated()) {
                    const hasEndpoints = ['/check', '/register', '/login', '/forgotpass', '/resetpass/', '/confirm/'].filter(uri => {
                      return this.previousUri && this.previousUri.startsWith(uri)
                    });
                    if (! this.previousUri || !hasEndpoints.length) {
                      this.router.navigate(['check']);
                    }
                    setTimeout(() => {
                      this.loading = false;
                      if (this.platform.is('cordova')) {
                        this.splashScreen.hide();
                      }
                    }, 200);
                    return;
                  }

                  this.dataService.setAuthName(this.auth.user.name);
                  this.dataService.setAuthEmail(this.auth.user.email);
                  
                  if (this.platform.is('cordova')) {
                    this.firebaseService.init();
                  }

                  if (! this.redirect) {
                    setTimeout(() => {
                      this.loading = false;
                      if (this.platform.is('cordova')) {
                        this.splashScreen.hide();
                      }
                    }, 200);
                    return;
                  }
                  this.redirect = false;

                  const hasEndpoints = ['/check', '/register', '/login', '/forgotpass', '/resetpass/', '/confirm/'].filter(uri => {
                    return this.previousUri && this.previousUri.startsWith(uri)
                  });
                  if (this.previousUri && !hasEndpoints.length) {
                    this.router.navigate([this.previousUri]);
                    setTimeout(() => {
                      this.loading = false;
                      if (this.platform.is('cordova')) {
                        this.splashScreen.hide();
                      }
                    }, 200);
                    return;
                  }

                  if (! this.auth.user.setup_at) {
                    this.router.navigate(['setup', 'invite']);
                    if (this.platform.is('cordova')) {
                      this.splashScreen.hide();
                    }
                    return;
                  }

                  if (! this.dataService.getIntro()) {
                    this.router.navigate(['setup', 'intro']);
                    this.loading = false;
                    if (this.platform.is('cordova')) {
                      this.splashScreen.hide();
                    }
                    return;
                  }

                  const theme = this.dataService.getTheme();
                  if (! theme.background || ! theme.theme) {
                    this.router.navigate(['setup', 'background']);
                    setTimeout(() => {
                      this.loading = false;
                      if (this.platform.is('cordova')) {
                        this.splashScreen.hide();
                      }
                    }, 200);
                    return;
                  }

                  this.router.navigate(['members']);
                  setTimeout(() => {
                    this.loading = false;
                    if (this.platform.is('cordova')) {
                      this.splashScreen.hide();
                    }
                  }, 200);
                });

                this.authService.init();
            });

          this.languageService.init();
        });
    });
  }

  gotoCourses() {
    if (this.isIntro || ! this.auth.user || ! this.auth.user.setup_at) {
      return;
    }

    this.router.navigate(['members', 'courses']);
  }

  gotoGoals() {
    if (this.isIntro || ! this.auth.user || ! this.auth.user.setup_at || ! this.auth.user.completed_at) {
      return;
    }

    this.router.navigate(['members', 'goals']);
  }

  gotoNudges() {
    if (this.isIntro || ! this.auth.user || ! this.auth.user.setup_at) {
      return;
    }

    this.router.navigate(['members', 'nudges']);
  }

  gotoProfile() {
    if (this.isIntro || ! this.auth.user || ! this.auth.user.setup_at) {
      return;
    }

    this.router.navigate(['members', 'profile']);
  }

  retryError() {
    this.errorService.click();
  }

  hideError() {
    this.errorService.hide();
  }
}
