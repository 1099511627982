export const environment = {
  production: true
};

export const API_HOST = 'https://api.driveiq.co.uk';
export const API_CLIENT_ID = 1;
export const API_CLIENT_SECRET = 'CfFtFOpqsPuLqyigKFnmX8bYC21372PeuyabbV3q';

export const CDN_HOST = 'https://api.driveiq.co.uk';

export const SENTRY_DSN = 'https://4991e03c2acd48299e2476ab58d05e61@sentry.io/1763072';