import { OnInit } from '@angular/core';
import { ThemeService } from '../../services/theme.service';
var BackgroundComponent = /** @class */ (function () {
    function BackgroundComponent(themeService) {
        this.themeService = themeService;
        this.themeStyle = '';
        this.themeType = '';
        this.themeTempBlur = null;
        this.theme = {
            background: null,
            theme: {
                id: null,
                backgroundId: null,
                color1: null,
                color2: null,
                color3: null,
                color4: null,
                color5: null,
            },
            blur: null
        };
        this.themeSub = null;
    }
    BackgroundComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.themeSub = this.themeService.state
            .subscribe(function () {
            _this.theme = _this.themeService.getTheme();
            _this.themeTempBlur = _this.themeService.getTempBlur();
            _this.themeType = _this.themeService.getBackgroundType();
        });
    };
    BackgroundComponent.prototype.ngOnDestroy = function () {
        this.themeSub && this.themeSub.unsubscribe();
    };
    return BackgroundComponent;
}());
export { BackgroundComponent };
