import { AuthenticationService } from '../services/authentication.service';
var AuthInterceptor = /** @class */ (function () {
    function AuthInterceptor(authService) {
        this.authService = authService;
    }
    AuthInterceptor.prototype.intercept = function (req, next) {
        var authToken = this.authService.getToken();
        if (authToken) {
            var authReq = req.clone({
                headers: req.headers.set('Authorization', authToken)
            });
            // send cloned request with header to the next handler.
            return next.handle(authReq);
        }
        return next.handle(req);
    };
    return AuthInterceptor;
}());
export { AuthInterceptor };
